import { 
  FETCH_LICENSE,
  FETCH_LICENSE_SUCCESS,
  FETCH_LICENSE_FAILED,
  EDIT_LICENSE
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    licenses:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
const licreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_LICENSE:
        return {
          ...state,
          loading:true
        };
      case FETCH_LICENSE_SUCCESS:
        return {
          ...state,
          licenses:action.payload,
          loading:false
        };
      case FETCH_LICENSE_FAILED:
        return {
          ...state,
          licenses:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_LICENSE:
        return state;
      default:
        return state;
    }
  };

  export default licreducer;