import React,{ useEffect } from 'react';
import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { fetchLic } from "../actions/licactions";
import { fetchProjects } from "../actions/projectactions";
import { fetchRejected } from "../actions/rejectactions";

function AuthLoading(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    useEffect(()=>{
        if(auth.info){
            dispatch(fetchLic());
            dispatch(fetchProjects());
            dispatch(fetchRejected());
        }

    },[auth.info,dispatch]);

    return (
        auth.loading? <CircularLoading/>:props.children
    )
}

export default AuthLoading;