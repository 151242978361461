import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import ProtectedRoute from './components/ProtectedRoute';
import Login from './views/Login';
import { fetchUser}  from "./actions/authactions";
import AuthLoading from './components/AuthLoading';
import Licenses from './views/Licenses';
import Projects from './views/Projects';
import Rejected from './views/Rejected';
import Validate from './views/Validate';
import AddLicense from './views/AddLicense';
import CheckedList from './views/Checked';

function App() {
  store.dispatch(fetchUser());
  return (
    <Provider store={store}>
      <AuthLoading>
        <Router>
          <Switch>
            <ProtectedRoute exact component={Projects} path="/"/>
            <ProtectedRoute exact component={Licenses} path="/licenses"/>
            <ProtectedRoute exact component={Rejected} path="/rejected"/>
            <ProtectedRoute exact component={CheckedList} path="/checked"/>
            <ProtectedRoute exact component={Validate} path="/validate"/>
            <ProtectedRoute exact component={AddLicense} path="/addlicense"/>
            <Route component={Login} path="/login"/>
          </Switch>
        </Router>
      </AuthLoading>
    </Provider>
  );
}

export default App;