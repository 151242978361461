import {
    FETCH_REJECTED,
    FETCH_REJECTED_SUCCESS,
    FETCH_REJECTED_FAILED,
    EDIT_REJECTED
  } from "./types";
  import { rejectedRef,rejectEditRef } from "../config/firebase";
  
  
  export const fetchRejected = () => dispatch => {

    function convertObjtoArr (input) {
      let output = [];
      Object.keys(input).forEach(key=>{
        output.push({accessTime: input[key].accessTime, reason: input[key].reason, dataStr: JSON.stringify(input[key].data)})
      })
      return output;
    }

    dispatch({
      type: FETCH_REJECTED,
      payload: null
    });

    rejectedRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          let innerArr = data[i].hasOwnProperty('entries')?convertObjtoArr(data[i].entries):[];
          data[i].id = i;
          data[i].items = innerArr;
          data[i].pcount = innerArr.length;
          data[i].lastUpdated = innerArr.length> 0?innerArr[innerArr.length - 1].accessTime: null;
          return data[i]
        });

        dispatch({
          type: FETCH_REJECTED_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_REJECTED_FAILED,
          payload: "No data available."
        });
      }
    });
  };
  
  export const toggleRejected = (item) => dispatch => {
    dispatch({
      type: EDIT_REJECTED,
      payload: { item }
    });
    rejectEditRef(item.id).update({checked: !item.checked})

  }