import React from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
}from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

import logo from '../assets/logo.png';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { signOut }  from "../actions/authactions";

function AppMenu() {
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

    return (
    <div>
      <div style={{ display: 'flex', backgroundColor:'#444444', justifyContent:'center' }}>
        <img style={{ marginTop: '20px', marginBottom: '20px', width: '120px', height: '120px' }} src={logo} alt="Logo" />
      </div>
      <Divider/>
      <MenuList>
        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <Typography variant="inherit">Projects</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/licenses">
          <ListItemIcon>
            <ListIcon/>
          </ListItemIcon>
          <Typography variant="inherit">Licenses</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/rejected">
          <ListItemIcon>
            <ListIcon/>
          </ListItemIcon>
          <Typography variant="inherit">Rejected</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/checked">
          <ListItemIcon>
            <ListIcon/>
          </ListItemIcon>
          <Typography variant="inherit">Checked</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/addlicense">
          <ListItemIcon>
            <ListIcon/>
          </ListItemIcon>
          <Typography variant="inherit">Add License</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/validate">
          <ListItemIcon>
            <ListIcon/>
          </ListItemIcon>
          <Typography variant="inherit">Validate</Typography>
        </MenuItem>
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;