import {
    FETCH_LICENSE,
    FETCH_LICENSE_SUCCESS,
    FETCH_LICENSE_FAILED,
    EDIT_LICENSE
  } from "./types";
  import { licRef,licEditRef, projectEditRef,innerProjectRef } from "../config/firebase";
  
  
  export const fetchLic = () => dispatch => {

    function convertObjtoArr (input) {
      let output = [];
      Object.keys(input).forEach(key=>{
        output.push({projectId:key, blocked: !input[key]})
      })
      return output;
    }

    dispatch({
      type: FETCH_LICENSE,
      payload: null
    });
    licRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          data[i].wixemails = data[i].wixemails? Object.values(data[i].wixemails).toString().replace(/,/g, "\n"): '';
          data[i].projects = (data[i] && data[i].projects) ? convertObjtoArr(data[i].projects): [];
          data[i].pcount = ((data[i] && data[i].projects) ? convertObjtoArr(data[i].projects): []).length;
          return data[i]
        });
        dispatch({
          type: FETCH_LICENSE_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_LICENSE_FAILED,
          payload: "No data available."
        });
      }
    });
  };
  
  export const toggleLic = (item) => dispatch => {
    let blocked = item.blocked? true: false;
    dispatch({
      type: EDIT_LICENSE,
      payload: { item }
    });
    if(item.projects){
      for(let i=0;i<item.projects.length;i++){
         projectEditRef(item.projects[i].projectId).update({blocked:!blocked});
         innerProjectRef(item.id, item.projects[i].projectId).set(blocked);
      }
    }
    licEditRef(item.id).update({blocked: !blocked});
  }

  export const toggleInnerProject = (licItem, projectItem) => dispatch => {
    let blocked = projectItem.blocked? true: false;
    dispatch({
      type: EDIT_LICENSE,
      payload: { licItem, projectItem }
    });
    projectEditRef(projectItem.projectId).update({blocked:!blocked});
    innerProjectRef(licItem.id, projectItem.projectId).set(blocked);
  }
  