import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { FirebaseConfig } from "./keys";
firebase.initializeApp(FirebaseConfig);

const databaseRef = firebase.database().ref();
export const authRef = firebase.auth();

export const licRef = databaseRef.child("licenses");
export const licEditRef = (id) => databaseRef.child("licenses/"+ id);

export const projectsRef = databaseRef.child("projects");
export const projectEditRef = (id) => databaseRef.child("projects/"+ id);

export const rejectedRef = databaseRef.child("rejected");
export const rejectEditRef = (id) => databaseRef.child("rejected/"+ id);

export const innerProjectRef = (lId, pId) => databaseRef.child("licenses/"+ lId + "/projects/" + pId);