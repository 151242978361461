import { 
    FETCH_PROJECTS,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILED,
    EDIT_PROJECT
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    projects:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
const projectreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_PROJECTS:
        return {
          ...state,
          loading:true
        };
      case FETCH_PROJECTS_SUCCESS:
        return {
          ...state,
          projects:action.payload,
          loading:false
        };
      case FETCH_PROJECTS_FAILED:
        return {
          ...state,
          projects:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_PROJECT:
        return state;
      default:
        return state;
    }
  };

  export default projectreducer;