import React, { useState, useEffect , useRef} from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import FlipIcon from '@material-ui/icons/Flip';
import { Modal } from '@material-ui/core';
import AlertDialog from '../components/AlertDialog';
import { toggleLic, toggleInnerProject }  from "../actions/licactions";
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit3:{
    width:'100%',
    borderRadius:3,
    marginTop:2,
    padding:4
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export default function ActiveList() {

  const [data, setData] = useState([]);
  const licdata = useSelector(state => state.licdata);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const [rowIndex,setRowIndex] = useState();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const columns = [
    { title: 'Purchase Code', field: 'id', editable:'never' },
    { title: "Blocked", field: 'blocked', type:'boolean'},
    { title: 'Item Name', field: 'item_name', editable:'never'},
    { title: 'Wix Emails', field: 'wixemails', editable: 'never'},
    { title: 'Buyer', field: 'buyer', editable:'never' },
    { title: 'Amount', field: 'amount', editable:'never' },
    { title: 'Purchase Date', field: 'sold_at', editable:'never',  render: rowData => new Date(rowData.sold_at).toLocaleString()},
    { title: 'Purchase Count', field: 'purchase_count', editable:'never'},
    { title: "Multiple Projects", field: "pcount"}
  ];

  const subcolumns = [
    { title: "Project",  field: 'projectId', editable:'never' },
    { title: "Blocked", field: 'blocked' , type: 'boolean', editable:'never'}
  ];
  
  useEffect(() => {
    if (licdata.licenses) {
        const sortedArr = licdata.licenses.sort((a, b) => {
            return  b.pcount - a.pcount;
        });
        setData(sortedArr);
    } else {
        setData([]);
    }
  }, [licdata.licenses]);
  
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleClose = () => {
    setOpen(false);
  }

  return (
    licdata.loading ? <CircularLoading /> :
    <div>
      <MaterialTable
        title="Active Licenses"
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          grouping: true,
          pageSize: 20
        }}
        actions={[
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <ListIcon />
            </div>,
            onClick: (event, rowData) => {
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            }
          }),
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <FlipIcon />
            </div>,
            onClick: (event, rowData) => {
              dispatch(toggleLic(rowData));
            }
          })
        ]}
      />
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={"Projects Attached"}
            columns={subcolumns }
            data={data[rowIndex] && data[rowIndex].projects? data[rowIndex].projects: []}
            options={{
              exportButton: true,
            }}
            actions={[
              rowData => ({
                icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                    <FlipIcon />
                </div>,
                onClick: (event, rowData) => {
                  dispatch(toggleInnerProject(data[rowIndex], rowData));
                }
              }),
              rowData => ({
                icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                    <LanguageIcon />
                </div>,
                onClick: (event, rowData) => {
                  window.open("https://"+ rowData.projectId + ".web.app","_blank");
                }
              })
            ]}
          />
        </div>
      </Modal>
    </div>
  );
}
