import React, { useState } from 'react';
import {
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import AlertDialog from '../components/AlertDialog';

export default function AddLicense() {

  const [data,setData] = useState();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const updateLicense = () => {
    fetch(`https://us-central1-simplehttpfunctions.cloudfunctions.net/reqinit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json();
    })
    .then(json => {
        if(json && json.success) {
            setCommonAlert({open: true, msg: "Success"});
            setData(null);
        }else{
            setCommonAlert({open: true, msg: "Unable to Add"})
        }
    }).catch(error=>{
        setCommonAlert({open: true, msg: error.toString()})
    })
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleChange = (e) => {
    let obj = {...data};
    obj[e.target.name] = e.target.name === 'app_name'? e.target.value: e.target.value.trim();
    setData(obj);
  }

  return (
    <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="license"
                label={"License"}
                name="license"
                autoComplete="license"
                onChange={handleChange}
                value={data && data.license? data.license: ""}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="projectId"
                label={"Project Id"}
                name="projectId"
                autoComplete="projectId"
                onChange={handleChange}
                value={data && data.projectId? data.projectId: ""}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="app_name"
                label={"App Name"}
                name="app_name"
                autoComplete="app_name"
                onChange={handleChange}
                value={data && data.app_name? data.app_name: ""}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="app_identifier"
                label={"App Indentifier"}
                name="app_identifier"
                autoComplete="app_identifier"
                onChange={handleChange}
                value={data && data.app_identifier? data.app_identifier: ""}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contact_email"
                label={"Contact Email"}
                name="contact_email"
                autoComplete="contact_email"
                onChange={handleChange}
                value={data && data.contact_email? data.contact_email: ""}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="reqType"
                label={"Old Request Type"}
                name="reqType"
                autoComplete="reqType"
                onChange={handleChange}
                value={data && data.reqType? data.reqType: ""}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button onClick={updateLicense} variant="contained" color="primary">
                {"Add License"}
            </Button>
        </Grid>
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </Grid>
  );
}
