export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const FETCH_PROJECTS= "FETCH_PROJECTS";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILED = "FETCH_PROJECTS_FAILED";
export const EDIT_PROJECT = "EDIT_PROJECT";

export const FETCH_LICENSE = "FETCH_LICENSE";
export const FETCH_LICENSE_SUCCESS = "FETCH_LICENSE_SUCCESS";
export const FETCH_LICENSE_FAILED = "FETCH_LICENSE_FAILED";
export const EDIT_LICENSE = "EDIT_LICENSE";

export const FETCH_REJECTED = "FETCH_REJECTED";
export const FETCH_REJECTED_SUCCESS = "FETCH_REJECTED_SUCCESS";
export const FETCH_REJECTED_FAILED = "FETCH_REJECTED_FAILED";
export const EDIT_REJECTED = "EDIT_REJECTED";