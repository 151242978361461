import React, { useState, useEffect , useRef} from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { toggleRejected } from '../actions/rejectactions';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import FlipIcon from '@material-ui/icons/Flip';
import { Modal } from '@material-ui/core';
import AlertDialog from '../components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit3:{
    width:'100%',
    borderRadius:3,
    marginTop:2,
    padding:4
  },
  paper: {
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export default function RejectedList() {

  const [data, setData] = useState([]);
  const rejecteddata = useSelector(state => state.rejecteddata);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const [rowIndex,setRowIndex] = useState();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const columns = [
    { title: 'Project', field: 'id', editable:'never' },
    { title: "Count", field: "pcount" , editable:'never'},
    { title: "Last Updated",  field: 'lastUpdated', editable:'never' , render: rowData =>  rowData.lastUpdated? new Date(rowData.lastUpdated).toLocaleString():null},
  ];

  const subcolumns = [
    { title: "Access Time",  field: 'accessTime', editable:'never' , render: rowData =>  rowData.accessTime? new Date(rowData.accessTime).toLocaleString():null},
    { title: "Reason", field: 'reason' , editable:'never' },
    { title: "Data", field: 'dataStr', editable: 'never' }
  ];

  useEffect(() => {
    if (rejecteddata.rejectedList) {
      const sortedArr = rejecteddata.rejectedList.filter(i => !i.checked).sort((a, b) => {
          return  b.pcount - a.pcount;
      });
      setData(sortedArr);
    } else {
      setData([]);
    }
  }, [rejecteddata.rejectedList]);
  
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleClose = () => {
    setOpen(false);
  }

  return (
    rejecteddata.loading ? <CircularLoading /> :
    <div>
      <MaterialTable
        title="Rejected List"
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          grouping: true,
          pageSize: 20
        }}
        actions={[
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <ListIcon />
            </div>,
            onClick: (event, rowData) => {
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            }
          }),
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <FlipIcon />
            </div>,
            onClick: (event, rowData) => {
              dispatch(toggleRejected(rowData))
            }
          })
        ]}
      />
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={"Entries"}
            columns={subcolumns }
            data={data[rowIndex] && data[rowIndex].items? data[rowIndex].items: []}
            options={{
              exportButton: true,
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
