import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILED,
    EDIT_PROJECT
} from "./types";
import { projectsRef, projectEditRef } from "../config/firebase";


export const fetchProjects = () => dispatch => {
    dispatch({
        type: FETCH_PROJECTS,
        payload: null
    });
    projectsRef.on("value", snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
                data[i].id = i;
                data[i].visits = data[i].visits && data[i].visits > 0 ? data[i].visits: 0
                return data[i]
            });
            dispatch({
                type: FETCH_PROJECTS_SUCCESS,
                payload: arr
            });
        } else {
            dispatch({
                type: FETCH_PROJECTS_FAILED,
                payload: "No data available."
            });
        }
    });
};

export const toggleProject = (project, method) => dispatch => {
    let blocked = project.blocked? true: false;
    let approved = project.approved? true: false;
    dispatch({
        type: EDIT_PROJECT,
        payload: { project }
    });
    if (method === 'block') {
        projectEditRef(project.id).update({blocked:!blocked});
    } else {
        projectEditRef(project.id).update({approved:!approved});
    }
}