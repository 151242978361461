import { 
    FETCH_REJECTED,
    FETCH_REJECTED_SUCCESS,
    FETCH_REJECTED_FAILED,
    EDIT_REJECTED
    } from "../actions/types";
    
    export const INITIAL_STATE = {
      rejectedList:null,
      loading: false,
      error:{
        flag:false,
        msg: null
      }
    }
    
  const rejectedreducer = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case FETCH_REJECTED:
          return {
            ...state,
            loading:true
          };
        case FETCH_REJECTED_SUCCESS:
          return {
            ...state,
            rejectedList:action.payload,
            loading:false
          };
        case FETCH_REJECTED_FAILED:
          return {
            ...state,
            rejectedList:null,
            loading:false,
            error:{
              flag:true,
              msg:action.payload
            }
          };
        case EDIT_REJECTED:
          return state;
        default:
          return state;
      }
    };
  
    export default rejectedreducer;