import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import AlertDialog from '../components/AlertDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useSelector } from "react-redux";
import { toggleLic }  from "../actions/licactions";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Validate() {

  const [data, setData] = useState([]);
  const licdata = useSelector(state => state.licdata);
  const [license,setLicense] = useState('');
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [progress, setProgress] = React.useState(0);
  useEffect(() => {
    if (licdata.licenses) {
        const sortedArr = licdata.licenses.sort((a, b) => {
            return  b.pcount - a.pcount;
        });
        setData(sortedArr);
    } else {
        setData([]);
    }
  }, [licdata.licenses]);

  const valLicense = (license) =>  {
    return  new Promise((resolve, reject)=>{
        fetch(`https://us-central1-simplehttpfunctions.cloudfunctions.net/checkpcode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "license": license.trim()
            })
        }).then(async response => {
            const json = await response.json();
            resolve({data: json});
        })
        .then(json => {
            if(json && json.success) {
                reject({error:JSON.stringify(json.data)});
            }else{
                reject({error: "Not Found"});
            }
        }).catch(error=>{
            reject({error: error.toString()});
        })
    });
  };

  const checkLic = async() => {
    if(!(license && license.length>0)){
        setCommonAlert({open: true, msg: "License Blank"});
        return;
    }
    const res = await valLicense(license);
    if(res.error){
        setCommonAlert({open: true, msg: res.error});
        return;
    } 
    setCommonAlert({open: true, msg: JSON.stringify(res.data) });
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const bulkCheck = async () =>  {
    for(let i = 0; i< data.length; i++){
        const res = await valLicense(data[i].id);
        if(res.error){
            toggleLic(data[i]);
        }
        setProgress( parseInt((i+ 1) / data.length * 100) );  
    }
    setCommonAlert({open: true, msg: "Bulk Check Complete" });
  }


  return (
    <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={8}>
            {progress>0 && progress<100? 
                <Grid item xs={12}>
                    <CircularProgressWithLabel value={progress} />
                </Grid>
                :
                <Grid item xs={12}>
                    <p>The bulk check is very sentitive. Do not do it frequently.</p>
                </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button onClick={bulkCheck} variant="contained" color="primary" disabled={progress>0 && progress<100}>
                    {"Bulk Check"}
                </Button>
            </Grid>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="license"
                    label={"License"}
                    name="license"
                    autoComplete="license"
                    onChange={(e)=> setLicense(e.target.value)}
                    value={license}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button onClick={checkLic} variant="contained" color="primary">
                    {"Check License"}
                </Button>
            </Grid>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        </Grid>
    </Grid>
  );
}
