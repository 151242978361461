import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { toggleProject }  from "../actions/projectactions";
import FlipIcon from '@material-ui/icons/Flip';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LanguageIcon from '@material-ui/icons/Language';

export default function ProjectList() {

  const [data, setData] = useState([]);
  const projectdata = useSelector(state => state.projectdata);
  const dispatch = useDispatch();

  const columns = [
    { title: 'Project', field: 'id', editable:'never' },
    { title: 'License', field: 'license', editable:'never' },
    { title: 'Approved', field: 'approved', type:'boolean'},
    { title: 'Blocked', field: 'blocked', type:'boolean'},
    { title: 'App Identifier', field: 'app_identifier', editable:'never' },
    { title: 'App Name', field: 'app_name', editable:'never' },
    { title: 'App Type', field: 'appcat', editable:'never' },
    { title: 'Contact Email', field: 'contact_email' },
    { title: 'Old Request', field: 'old_request_type', editable:'never' },
    { title: 'First Install', field: 'firstInstall', editable:'never', render: rowData =>  rowData.firstInstall? new Date(rowData.firstInstall).toLocaleString():null},
    { title: 'Last Install', field: 'lastInstall', editable:'never', render:rowData =>  rowData.lastInstall? new Date(rowData.lastInstall).toLocaleString():null},
    { title: 'Install Count', field: 'installs', editable:'never'},
    { title: 'First Access', field: 'firstAccess', editable:'never', render: rowData => rowData.firstAccess? new Date(rowData.firstAccess).toLocaleString():null},
    { title: 'Last Access', field: 'lastAccess', editable:'never', render: rowData => rowData.lastAccess? new Date(rowData.lastAccess).toLocaleString():null},
    { title: 'Access Count', field: 'visits', editable:'never' }
  ];

  useEffect(() => {
    if (projectdata.projects) {
        const sortedArr = projectdata.projects.sort((a, b) => {
            return  b.visits - a.visits;
        });
        setData(sortedArr);
    } else {
        setData([]);
    }
  }, [projectdata.projects]);

  return (
    projectdata.loading ? <CircularLoading /> :
      <MaterialTable
        title="Projects List"
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          sorting: true,
          grouping: true,
          pageSize: 20
        }}
        actions={[
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <DoneAllIcon />
            </div>,
            onClick: (event, rowData) => {
              dispatch(toggleProject(rowData,'approve'));
            }
          }),
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <FlipIcon />
            </div>,
            onClick: (event, rowData) => {
              dispatch(toggleProject(rowData,'block'));
            }
          }),
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <LanguageIcon />
            </div>,
            onClick: (event, rowData) => {
              window.open("https://"+ rowData.id + ".web.app","_blank");
            }
          })
        ]}
      />
  );
}
